<template>
  <div v-if="visible">
    <div class="my-mask weui-animate-fade-in"></div>
    <div class="my-dialog weui-animate-fade-in">
      <div class="my-dialog__hd">
        <strong class="my-dialog__title">{{ showtitle }}</strong>
      </div>
      <div class="my-dialog__bd">
        {{ showcontent }}
      </div>
      <div class="my-dialog__ft">
        <a href="javascript:;" class="my-dialog__btn my-dialog__btn_primary" @click="handleOk()">确定</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  show: Boolean,
  title: {
    type: String,
    default: '标题'
  },
  content: {
    type: String,
    default: ''
  }
});
const emit = defineEmits<{
  (e: 'update:show', val: any): void,
  (e: 'reload'): void,
  (e: 'close'): void,
}>();

const visible = ref<boolean>(props.show);
const showtitle = ref('');
const showcontent = ref('');

watch(() => props.show, (newVal) => {
  visible.value = newVal;
});

watch(() => visible.value, (newVal) => {
  if (!newVal) {
    emit('update:show', false);
    emit('close');
  }
});

function init(title, content) {
  showtitle.value = title;
  showcontent.value = content;
}

function handleOk() {
  visible.value = false;
}

defineExpose({
  init
});
</script>

<style lang="less" scoped>
.my-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
}

.my-dialog {
  position: fixed;
  z-index: 5000;
  top: 50%;
  left: 16px;
  right: 16px;
  width: 320px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #fff;
  text-align: center;
  border-radius: 12px;
  overflow: hidden;
  max-height: 90%;
  transform: translate(0, -50%);

  .my-dialog__hd {
    padding: 16px 24px;
  }

  .my-dialog__bd {
    max-height: 640px;
    padding: 0 24px;
    font-size: 17px;
    line-height: 1.4;
    color: #0000008c;
    color: var(--weui-FG-1);
    overflow-y: auto;
  }

  .my-dialog__ft {
    position: relative;
    display: flex;
    align-items: center;

    .my-dialog__btn {
      flex: 1;
      display: block;
      line-height: 1.41176471;
      padding: 20px 8px;
      font-size: 17px;
      color: #576b95;
      color: var(--weui-LINK);
      font-weight: 500;
      text-decoration: none;
      user-select: none;
      position: relative;
      overflow: hidden;

      &.my-dialog__btn_primary {
        color: #1aad19;
      }
    }
  }
}
</style>