export function isDeFined(data) {
  return data !== undefined && data !== null;
}

export function isEmptyObject(data) {
  return JSON.stringify(data) === '{}';
}

// 字符串是否驼峰格式
export function isHump(str) {
  return /^on(\S)/.test(str);
}

/**
 * 邮箱
 * @param {*} s
 */
export function isEmail(s) {
  return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(
    s,
  );
}
// 验证是否为手机号码
export function isValidPhone(str) {
  var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
  if (!myreg.test(str)) {
    return false;
  } else {
    return true;
  }
}

/**
 * URL地址
 * @param {*}
 */
export function isURL(s) {
  return /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/.test(s);
}

/**
 * 获取url参数
 * @param name 参数名
 */
export function getQueryString(name) {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  let r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(decodeURI(r[2]));
  return null;
}

// 序列化对象
export function serialize(obj) {
  let str = [];
  for (let p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  }
  return str.join('&');
}

// 数组转对象
export function arrayToObject(datas, key, valueKey) {
  let obj = {};
  datas.forEach((item) => {
    obj[item[key]] = item[valueKey];
  });
  return obj;
}

// 深拷贝数据，并改变字段
export function deepChangeKey(arr = [], keys = {}) {
  const isNullKeys = isEmptyObject(keys);
  return arr.map((item) => {
    let data = {
      ...item,
    };
    if (!isNullKeys) {
      Object.keys(keys).forEach((key) => {
        data[key] = data[keys[key]];
      });
    }
    if (item.children && item.children.length > 0) {
      data.children = deepChangeKey(item.children);
    }
    return data;
  });
}

// 判断是否微信打开
export function isWeixin() {
  var ua = window.navigator.userAgent.toLowerCase();
  if (ua.indexOf('micromessenger') >= 0 || ua.indexOf('_sq_') >= 0) { }
  const str1 = 'micromessenger';
  const str2 = '_sq_';
  const arr1 = ua.match(/MicroMessenger/i);
  const arr2 = ua.match(/_SQ_/i);
  const flag1 = arr1 && arr1.length > 0;
  const flag2 = arr2 && arr2.length > 0;
  const flag3 = ua.indexOf(str1) >= 0;
  const flag4 = ua.indexOf(str2) >= 0;

  return flag1 || flag2 || flag3 || flag4;
}

// 获取微信授权跳转页面
export function getWechatAuthJumpURL(AppID: string, link?: string) {
  const host = location.origin;
  // const host = 'http://y242810h72.vicp.fun';
  if (!link) {
    link = host + location.hash;
  }
  const REDIRECT_URI = encodeURIComponent(link);
  const baseURL = `https://open.weixin.qq.com/connect/oauth2/authorize`;
  const SCOPE = 'snsapi_userinfo';
  const STATE = `STATE`;

  return `${baseURL}?appid=${AppID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=${SCOPE}&state=${STATE}#wechat_redirect`;
}

export async function setClipboardData(text) {
  try {
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(text);
      return Promise.resolve();
    } else {
      let textArea = document.createElement("textarea");
      textArea.value = text;
      textArea.style.position = "absolute";
      textArea.style.opacity = '0';
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      // 执行复制命令并移除文本框
      document.execCommand('copy');
      textArea.remove();
      return Promise.resolve();
    }
  } catch (err) {
    console.log(err);
    return Promise.reject();
  }
}

export function rafSetTimeout(callback, timer) {
  let rafId = 0;
  const start = Date.now();
  const loop = () => {
    const current = Date.now();
    if (current - start > timer) {
      callback();
    } else {
      rafId = requestAnimationFrame(loop);
    }
  }
  rafId = requestAnimationFrame(loop);
  return function () {
    cancelAnimationFrame(rafId);
  };
}

export function rafInterval(callback, timer) {
  let rafId = 0;
  let start = Date.now();
  const loop = () => {
    const current = Date.now();
    if (current - start > timer) {
      callback();
      start = Date.now();
    }
    rafId = requestAnimationFrame(loop);
  }
  rafId = requestAnimationFrame(loop);
  return function () {
    cancelAnimationFrame(rafId);
  };
}