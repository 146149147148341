import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layouts/index.vue';
import Redirect from '@/views/global/redirect.vue';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'site',
    meta: {
      title: '首页',
    },
    component: Layout,
    redirect: '/index',
    children: [
      {
        path: '/redirect/:path(.*)',
        component: Redirect,
      },
      {
        name: 'index',
        path: '/index',
        meta: {
          title: '首页',
        },
        component: () => import(`../views/home.vue`),
      },
      {
        name: '404',
        path: '/:pathMatch(.*)',
        meta: {
          title: '404',
        },
        component: () => import(`../views/global/nofound.vue`),
      },
    ]
  },
  {
    name: 'msgSuccess',
    path: '/msg/success',
    meta: {
      title: '操作成功',
    },
    component: () => import(`../views/msg/success.vue`),
  },
  {
    name: 'download',
    path: '/download',
    meta: {
      title: '下载',
    },
    component: () => import(`../views/global/download.vue`),
  },
  {
    name: 'doc',
    path: '/doc',
    meta: {
      title: '帮助文档',
    },
    component: Layout,
    redirect: '/doc/list',
    children: [
      {
        name: 'docList',
        path: '/doc/list',
        meta: {
          title: '帮助文档',
        },
        component: () => import(`@/views/doc/list.vue`),
      },
      {
        name: 'docInner',
        path: '/doc/inner',
        meta: {
          title: '帮助文档-内部',
        },
        component: () => import(`@/views/doc/inner.vue`),
      },
      {
        name: 'docPay',
        path: '/doc/pay',
        meta: {
          title: '帮助文档',
        },
        component: () => import(`@/views/doc/pay.vue`),
      },
      {
        name: 'docDetail',
        path: '/doc/detail',
        meta: {
          title: '帮助文档-详情',
        },
        component: () => import(`@/views/doc/detail.vue`),
      },
    ],
  },
  {
    name: 'home',
    path: '/home',
    meta: {
      title: '首页',
    },
    component: () => import(`@/views/bp/home.vue`),
  },
  {
    name: 'auth',
    path: '/auth',
    meta: {
      title: '登录',
    },
    component: () => import(`@/views/bp/auth.vue`),
  },
  {
    name: 'my',
    path: '/my',
    meta: {
      title: '我的',
    },
    component: () => import(`@/views/bp/my.vue`),
  },
  {
    name: 'bindTel',
    path: '/bind/tel',
    meta: {
      title: '绑定手机号',
    },
    component: () => import(`@/views/bp/bind/tel.vue`),
  },
  {
    name: 'examine',
    path: '/examine',
    meta: {
      title: '改单审批',
    },
    component: () => import(`@/views/bp/examine/index.vue`),
  },
  {
    name: 'examineDetail',
    path: '/examine/detail',
    meta: {
      title: '改单审批',
    },
    component: () => import(`@/views/bp/examine/detail.vue`),
  },
  {
    name: 'bp',
    path: '/bp/home',
    children: [
      {
        name: 'bphome',
        path: '/bp/home',
        meta: {
          title: '首页',
        },
        component: () => import(`@/views/bp/home.vue`),
      },
      {
        name: 'bpauth',
        path: '/bp/auth',
        meta: {
          title: '登录',
        },
        component: () => import(`@/views/bp/auth.vue`),
      },
      {
        name: 'bpmy',
        path: '/bp/my',
        meta: {
          title: '我的',
        },
        component: () => import(`@/views/bp/my.vue`),
      },
      {
        name: 'bpBindTel',
        path: '/bp/bind/tel',
        meta: {
          title: '绑定手机号',
        },
        component: () => import(`@/views/bp/bind/tel.vue`),
      },
      {
        name: 'bpClaims',
        path: '/bp/claims',
        meta: {
          title: '理赔审批',
        },
        component: () => import(`@/views/bp/claims/index.vue`),
      },
      {
        name: 'bpClaimsDetail',
        path: '/bp/claims/detail',
        meta: {
          title: '理赔审批',
        },
        component: () => import(`@/views/bp/claims/detail.vue`),
      },
      {
        name: 'bpQuotedPrice',
        path: '/bp/quotedprice',
        meta: {
          title: '报价',
        },
        component: () => import(`@/views/bp/quotedprice/index.vue`),
      },
      {
        name: 'bpQuotedPriceOffer',
        path: '/bp/quotedprice/offer',
        meta: {
          title: '报价',
        },
        component: () => import(`@/views/bp/quotedprice/offer.vue`),
      },
      {
        name: 'bpQuotedPriceDetail',
        path: '/bp/quotedprice/detail',
        meta: {
          title: '报价',
        },
        component: () => import(`@/views/bp/quotedprice/detail.vue`),
      },
    ],
  },
  {
    name: 'cx',
    path: '/cx/home',
    children: [
      {
        name: 'cxhome',
        path: '/cx/home',
        meta: {
          title: '首页',
        },
        component: () => import(`@/views/cx/home.vue`),
      },
      {
        name: 'cxauth',
        path: '/cx/auth',
        meta: {
          title: '登录',
        },
        component: () => import(`@/views/cx/auth.vue`),
      },
      {
        name: 'cxmy',
        path: '/cx/my',
        meta: {
          title: '我的',
        },
        component: () => import(`@/views/cx/my.vue`),
      },
      {
        name: 'cxBindTel',
        path: '/cx/bind/tel',
        meta: {
          title: '绑定手机号',
        },
        component: () => import(`@/views/cx/bind/tel.vue`),
      },
      {
        name: 'cxPay',
        path: '/cx/pay',
        meta: {
          title: '待支付',
        },
        component: () => import(`@/views/cx/pay/index.vue`),
      },
    ]
  }
];

export default routes;