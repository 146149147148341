<template>
  <div>
    <router-view />
    <ShowContent ref="showtext" v-model:show="textVisible" />
  </div>
</template>

<script lang="ts" setup>
import { baseURL } from '@/config/default.config';
// import EventBus from '@/utils/eventBus';
import { getQueryString } from '@/utils/util';
import ShowContent from '@cps/show-content.vue';
import VConsole from 'vconsole';

const { $eventBus } = getCurrentInstance().proxy;

const textVisible = ref(false);
const showtext = ref(null);

$eventBus.on('showText', (data: any) => {
  const { title, content } = data;
  showTextDialog(title, content);
});

function showTextDialog(title: string, content: string) {
  textVisible.value = true;
  showtext.value.init(title, content);
}

// 监听页面显示
window.addEventListener('pageshow', (event) => {
  // event.persisted：从缓存获取数据时为true，否则为false
  // window.performance.navigation.type 如果用户通过浏览器后退访问的页面 则值为2
  if (
    event.persisted ||
    (window.performance && window.performance.navigation.type === 2)
  ) {
    window.location.reload(); // 刷新页面
  }
});

window.addEventListener('popstate', function (event) {
  // 监听到了浏览器的返回按钮事件
  $eventBus.emit('onBack', event);
}, false);

document.addEventListener('visibilitychange', () => {
  if (document.hidden) {
    $eventBus.emit('onHide');
  } else {
    $eventBus.emit('onShow');
  }
});

onMounted(() => {
  const debug = getQueryString('debug');
  const flag: boolean = !/^http(s?):\/\/wechat.benpao56.com/.test(location.href);
  const flag2: boolean = !/^http:\/\/localhost:(\d)/.test(location.href);
  if (debug === '1' || (flag && flag2)) {
    new VConsole();
  }
  console.warn('页面地址', window.location.origin);
  console.warn('接口地址', baseURL);
});

defineOptions({
  name: 'App',
});
</script>

<style lang="less">
@import '@/styles/var.less'; // 变量
@import '@/styles/reset.less'; // 重置样式
@import '@/styles/global.less'; // 全局样式
@import '@/styles/transition.less'; // 渐变
@import '@/styles/animation.less';
</style>