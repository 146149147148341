import weui from 'weui.js';

export function toast(title: string, duration: number = 1500) {
  return weui.toast(title, duration);
}

export function loading(title: string) {
  return weui.loading(title);
}

export function alert(content: string) {
  return new Promise<void>((resolve) => {
    weui.alert(content, {
      title: '提示',
      buttons: [{
        type: 'primary',
        label: '确定',
        onClick: () => {
          resolve();
        }
      }]
    });
  });
}

export function confirm(title: string = '提示', content: string) {
  return new Promise<void>((resolve, reject) => {
    weui.confirm(content, {
      title: title,
      buttons: [{
        type: 'default',
        label: '取消',
        onClick: () => {
          reject();
        }
      }, {
        type: 'primary',
        label: '确定',
        onClick: () => {
          resolve();
        }
      }]
    });
  });
}

export function dialog(title: string, content: string) {
  return new Promise<void>((resolve, reject) => {
    weui.dialog({
      title: title,
      content: content,
      buttons: [{
        type: 'default',
        label: '取消',
        onClick: () => {
          reject();
        }
      }, {
        type: 'primary',
        label: '确定',
        onClick: () => {
          resolve();
        }
      }]
    });
  });
}

interface DatePickerOption {
  title: String
  defaultValue: Number[]
  start: String | Number
  end: String | Number
  cron: String
  onChange: Function
}
// 日期控件
export function datePicker(options: DatePickerOption) {
  const now = new Date();
  let defaultValue = [];
  if (!options.defaultValue || options.defaultValue.length === 0) {
    defaultValue = [now.getFullYear(), now.getMonth() + 1, now.getDate()];
  }
  const title = options.title || '日期选择器';
  const start = options.start || new Date('1900-01-01');
  const end = options.end || new Date('2050-12-31 23:59:59');
  const cron = options.cron || '* * *';
  /* cron 表达式后三位
  * 示例：
  *  * * *                每天
  *  5 * *                每个月的5日
  *  1-10 * *             每个月的前10日
  *  1,5,10 * *           每个月的1号、5号、10号
  *  *\/2 * *             每个月的 1、3、5、7...日，注意写的时候斜杠“/”前面没有反斜杠“\”，这是因为是注释所以需要转义
  *  * 2 0                2月的每个周日
  *  * * 0,6              每个周末
  *  * * 3                每周三
  */
  const onChange = options.onChange || function () { };
  return new Promise((resolve, reject) => {
    weui.datePicker({
      title,
      defaultValue,
      start,
      end,
      cron,
      onChange: onChange,
      onConfirm(result) {
        resolve(result);
      },
      onClose() {
        reject();
      },
      id: 'datePicker',
    });
  });
}

interface PickerOption {
  defaultValue: Number[]
  confirmText: String
  onChange: Function
}
export function picker(datas: Array<Object>, options: PickerOption) {
  const defaultValue = !options.defaultValue || options.defaultValue.length === 0 ? [0] : options.defaultValue;
  const confirmText = options.confirmText || '确定';
  const onChange = options.onChange || function () { };
  // console.log('defaultValue', defaultValue);
  return new Promise((resolve, reject) => {
    weui.picker(datas, {
      defaultValue,
      confirmText,
      onChange: onChange,
      onConfirm(result) {
        resolve(result);
      },
      onClose() {
        reject();
      },
      container: 'body',
      id: 'picker',
    });
  });
}

export function topTips(message: string) {
  return weui.topTips(message);
}

export default weui;